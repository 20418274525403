import { combineReducers } from 'redux';

import auth from './auth';
import meeting from './meeting';

const combinedReducers = combineReducers({
  auth,
  meeting,
});

export default combinedReducers;
