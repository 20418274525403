import React from 'react';

import propTypes from 'prop-types';

import MeetingPasswordView from '../MeetingPasswordView';

import ExternalVideoComponent from '../ExternalVideoComponent';

class MeetingStartedView extends React.Component {
  constructor (props) {
    super(props);

    this.state = {

    };
  }

  render () {
    const {
      isPasswordAccessEnabled,
      isModerator,
      isPasswordVerified,
      history,
      match,
    } = this.props;

    return (
      <div>
        {
          isPasswordAccessEnabled && !isModerator && !isPasswordVerified ?
            <div>
              <MeetingPasswordView
                history={history}
                match={match}
              />
            </div>
            :
            <div>
              <ExternalVideoComponent
                history={history}
                match={match}
              />
            </div>
        }
      </div>
    );
  }

}

MeetingStartedView.propTypes = {
  isPasswordAccessEnabled: propTypes.bool,
  isModerator: propTypes.bool,
  isPasswordVerified: propTypes.bool,
  history: propTypes.object,
  match: propTypes.object,
};

MeetingStartedView.defaultProps = {
  isPasswordAccessEnabled: false,
  isModerator: false,
  isPasswordVerified: false,
};


export default MeetingStartedView;
