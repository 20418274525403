import {
  clearAllMeetingDetails,
  getAllMeetings,
} from '../../redux/meeting/actions';

export const mapStateToProps = state => ({
  meetings: state.meeting.allMeetings,
  adminToken: state.auth.adminToken,
  hasMore: state.meeting.hasMorePagesOfMeetingDataToFetch,
  pageNumberToFetchData: state.meeting.pageNumberForFetchingAllMeetings,
});

export const mapDispatchToProps = dispatch => ({
  getAllMeetingsData: (pageNumber = 0) => {
    dispatch(getAllMeetings(pageNumber));
  },
  clear: () => {
    dispatch(clearAllMeetingDetails());
  },
});
