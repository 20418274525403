import { connect } from 'react-redux';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './props';

import ExternalVideoComponent from './ExternalVideoComponent';

export default connect(mapStateToProps, mapDispatchToProps)(ExternalVideoComponent);
