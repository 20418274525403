import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';

import './App.css';


// RELATIVE IMPORTS
import {
  // theme,
  muiTheme,
} from './utils/uiTheme';
import configureReduxStore from './utils/configureReduxStore';
import Routes from './Routes';

const reduxStore = configureReduxStore;

const App = () => {
  return (
    <Provider store={reduxStore}>
      <MuiThemeProvider theme={muiTheme}>
        <Routes />
      </MuiThemeProvider>
    </Provider>
  );
};

export default App;
