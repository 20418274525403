import { createNewMeeting } from '../../redux/meeting/actions';

export const mapStateToProps = state => ({
  adminToken: state.auth.adminToken,
});

export const mapDispatchToProps = dispatch => ({
  create: (
    startTime,
    endTime,
    title,
    enableMeetingPassword,
    meetingPassword,
    enableModeratorControl,
    selectedModerators,
    selectedMeetingType,
    selectedParticipants,
    history,
  ) => {
    dispatch(createNewMeeting(
      startTime,
      endTime,
      title,
      enableMeetingPassword,
      meetingPassword,
      enableModeratorControl,
      selectedModerators,
      selectedMeetingType,
      selectedParticipants,
      history,
    ));
  },
});
