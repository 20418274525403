import { connect } from 'react-redux';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './props';

import MeetingPasswordView from './MeetingPasswordView';

export default connect(mapStateToProps, mapDispatchToProps)(MeetingPasswordView);
