import { connect } from 'react-redux';

import HomePageView from './HomePageView';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './props';

export default connect(mapStateToProps, mapDispatchToProps)(HomePageView);
