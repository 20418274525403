import { connect } from 'react-redux';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './props';

import JoinMeetingView from './JoinMeetingView';

export default connect(mapStateToProps, mapDispatchToProps)(JoinMeetingView);
