import React from 'react';
import propTypes from 'prop-types';

import { Button, TextField } from '@material-ui/core';

import './joinMeetingView.css';
import TopNavBar from '../TopNavBar';
import { emailErrorMessages, joinMeetingDisabledButtonTitle, nameErrorMessages } from '../../configFiles/config';
import validator from 'validator';

class JoinMeetingView extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      nameErrorMessage: nameErrorMessages.default,
      emailErrorMessage: emailErrorMessages.default,
    };
  }

  componentDidMount () {
    // Add a check to see if the meeting hasn't ended.
    const {
      getDetails,
      match,
    } = this.props;

    const {
      meetingId,
    } = match.params;

    getDetails(meetingId);
  }

  handleNameUpdate = (event) => {
    const name = event.target.value;
    let nameErrorMessage;

    if (name === '') {
      nameErrorMessage = nameErrorMessages.default;
    }

    this.setState(() => ({
      name,
      nameErrorMessage,
    }));
  }

  handleEmailUpdate = (event) => {
    const email = event.target.value;

    let emailErrorMessage;
    if (!email) {
      emailErrorMessage = emailErrorMessages.default;
    } else if (!validator.isEmail(email)) {
      emailErrorMessage = emailErrorMessages.validation;
    }

    this.setState(() => ({
      email,
      emailErrorMessage,
    }));
  }

  joinCurrentMeeting = () => {
    const {
      joinMeeting,
      match,
      history,
    } = this.props;

    const meetingId = match && match.params && match.params.meetingId;

    const {
      name,
      email,
      nameErrorMessage,
      emailErrorMessage,
    } = this.state;

    if ((nameErrorMessage === undefined) && (emailErrorMessage === undefined)) {
      joinMeeting(meetingId, name, email, history);
    }
  }

  render () {
    const {
      title,
    } = this.props;

    const {
      emailErrorMessage,
      nameErrorMessage,
    } = this.state;

    const disableJoinButton = (emailErrorMessage !== undefined) || (nameErrorMessage !== undefined);

    return (
      <div className='joinMeetingViewContainer'>
        <div className='joinMeetingTopNavBarContainer'>
          <TopNavBar />
        </div>
        <div className='joinMeetingTitleContainer'>
          {title}
        </div>
        <div className='joinMeetingContentContainer'>
          <div className='joinMeetingNameField'>
            <TextField
              id='meetingPasswordInputField'
              // label="Name"
              placeholder='Participant Name'
              variant='outlined'
              required={true}
              // errortext={enteredPasswordErrorMessage}
              error={nameErrorMessage !== undefined}
              helperText={nameErrorMessage}
              onChange={this.handleNameUpdate}
              style={{
                justifyContent: 'center',
                display: 'flex',
                width: '50%',
                paddingTop: '5px',
              }}
            />
          </div>
          <div className='joinMeetingEmailField'>
            <TextField
              id='adminLoginEmailField'
              // label="Name"
              type='email'
              placeholder='user@domain.com'
              variant='outlined'
              required={true}
              helperText={emailErrorMessage}
              error={emailErrorMessage !== undefined}
              onChange={this.handleEmailUpdate}
              style={{
                justifyContent: 'center',
                display: 'flex',
                width: '50%',
                paddingTop: '5px',
              }}
            />
          </div>
          <div
            className='joinMeetingButton'
            title={
              disableJoinButton ?
                joinMeetingDisabledButtonTitle
                :
                ''
            }
            style={
              disableJoinButton ?
                {
                  cursor: 'not-allowed',
                }
                :
                {
                  
                }
            }
          >
            <Button
              id='adminCreateMeetingButton'
              variant='contained'
              onClick={this.joinCurrentMeeting}
              className={
                disableJoinButton ?
                  'adminJoinMeetingButtonDisabled'
                  :
                  'adminJoinMeetingButton'
              }
              title={'Click here to login'}
              disabled={disableJoinButton}
            >
              Join
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

JoinMeetingView.propTypes = {
  joinMeeting: propTypes.func,
  getDetails: propTypes.func,
  match: propTypes.object,
  title: propTypes.string,
  history: propTypes.object,
};

JoinMeetingView.defaultProps = {
  joinMeeting: () => { },
  getDetails: () => { },
  title: '',
  history: {},
};

export default JoinMeetingView;
