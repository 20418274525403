import React from 'react';

import propTypes from 'prop-types';

import './adminViewAllMeetingsView.css';

import TopNavBar from '../TopNavBar';
import { Button } from '@material-ui/core';

class AdminViewAllMeetingsView extends React.Component {
  componentDidMount () {
    const {
      getAllMeetingsData,
      adminToken,
    } = this.props;

    if (!adminToken) {
      window.location.assign('/admin/login');
    }

    getAllMeetingsData();
  }

  componentWillUnmount () {
    const {
      clear,
    } = this.props;

    clear();
  }

  loadMoreMeetings = () => {
    const {
      hasMore,
      pageNumberToFetchData,
      getAllMeetingsData,
    } = this.props;

    if (hasMore) {
      getAllMeetingsData(pageNumberToFetchData);
    }
  }

  render () {
    const {
      meetings,
      history,
      hasMore,
    } = this.props;


    return (
      <div className='adminViewAllMeetingsContainer'>
        <div className='adminViewAllMeetingsTopNavBarContainer'>
          <TopNavBar />
        </div>
        <div className='adminViewAllMeetings'>
          {
            Object.keys(meetings).map((meetingId) => {
              return (
                <div
                  key={meetingId}
                  className='adminViewAllMeetingDetails'
                  title={`Click to view the details of meeting : ${meetingId}`}
                  onClick={() => {
                    history.push(`/admin/meeting/view/${meetingId}`);
                  }}
                >
                  <div className='adminViewAllMeetingsMeetingId'>
                    {meetingId}
                  </div>
                  <div className='adminViewAllMeetingsMeetingTitle'>
                    {meetings[meetingId].title}
                  </div>
                </div>
              );
            })
          }
        </div>
        <div className='adminViewAllMeetingsLoadMoreMeetingsButton'>
          <Button
            id='adminLoadMoreMeetingsButton'
            variant='contained'
            onClick={this.loadMoreMeetings}
            className={
              hasMore ?
                'adminLoadMoreMeetingsButton'
                :
                'disabledAdminLoadMoreMeetingsButton'
            }
            disabled={!hasMore}
          >
            Load more
          </Button>
        </div>
      </div>
    );
  }
}

AdminViewAllMeetingsView.propTypes = {
  meetings: propTypes.object,
  getAllMeetingsData: propTypes.func,
  adminToken: propTypes.string,
  history: propTypes.object,
  hasMore: propTypes.bool,
  pageNumberToFetchData: propTypes.number,
  clear: propTypes.func,
};

AdminViewAllMeetingsView.defaultProps = {
  getAllMeetingsData: () => { },
  hasMore: true,
  pageNumberToFetchData: 0,
  clear: () => { },
};

export default AdminViewAllMeetingsView;
