export const mapStateToProps = state => ({
  token: state.auth.token,
  connectingVideoId: state.meeting.connectingVideoId,
  name: state.meeting.name,
  email: state.meeting.email,
  meetingId: state.meeting.meetingId,
  isModerator: state.meeting.isModerator,
  useMyMeetingMicroService: state.meeting.useMyMeetingMicroService,
});

export const mapDispatchToProps = () => ({

});

