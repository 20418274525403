import React from 'react';
import propTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import validator from 'validator';

import './adminLoginComponent.css';

import TopNavBar from '../TopNavBar';

class AdminLoginComponent extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      email: '',
      emailErrorMessage: undefined,
      password: '',
    };
  }

  handleEmailUpdate = (event) => {
    const value = event.target.value;
    if (validator.isEmail(value)) {
      this.setState(() => ({
        email: value,
        emailErrorMessage: undefined,
      }));
    } else {
      this.setState(() => ({
        email: '',
        emailErrorMessage: 'Invalid email',
      }));
    }
  };

  handlePasswordUpdate = (event) => {
    const value = event.target.value;
    this.setState(() => ({
      password: value,
    }));
  };

  adminLogin = () => {
    const {
      emailErrorMessage,
      email,
      password,
    } = this.state;

    const dontProceedWithLogin = Boolean(emailErrorMessage || !email || !password);

    if (!dontProceedWithLogin) {
      const {
        login,
        history,
      } = this.props;
      login(email, password, history);
    }
  };

  render () {
    const {
      emailErrorMessage,
      email,
      password,
    } = this.state;

    const isLoginButtonDisabled = Boolean(emailErrorMessage || !email || !password);

    return (
      <div className='adminLoginComponentContainer'>
        <TopNavBar />
        <div className='adminLoginDetailsContainer'>
          <div className='adminLoginInputContainer'>
            <TextField
              id='adminLoginEmailField'
              // label="Name"
              type='email'
              placeholder='user@domain.com'
              variant='outlined'
              required={true}
              autoFocus
              helperText={emailErrorMessage}
              error={emailErrorMessage !== undefined}
              onChange={this.handleEmailUpdate}
              style={{
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
                paddingTop: '5px',
              }}
            />
            <TextField
              id='adminLoginPasswordField'
              // label="Name"
              type='password'
              placeholder='**********'
              variant='outlined'
              required={true}
              onChange={this.handlePasswordUpdate}
              style={{
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
                paddingTop: '5px',
              }}
            />
          </div>
          <div
            className={
              isLoginButtonDisabled ?
                'adminLoginDisabledButtonContainer'
                :
                'adminLoginButtonContainer'
            }
          >
            <Button
              id='adminLoginButton'
              variant='contained'
              onClick={this.adminLogin}
              className={
                isLoginButtonDisabled ?
                  'adminLoginDisabledButton'
                  :
                  'adminLoginButton'
              }
              title={'Click here to login'}
              disabled={isLoginButtonDisabled}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

AdminLoginComponent.propTypes = {
  login: propTypes.func,
  history: propTypes.object,
};

AdminLoginComponent.defaultProps = {
  login: () => { },
  history: {},
};

export default AdminLoginComponent;
