import TopNavBar from './TopNavBar';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './props';

import { connect } from 'react-redux';

export default connect(mapStateToProps, mapDispatchToProps)(TopNavBar);
