import { connect } from 'react-redux';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './props';

import ModeratorTopNavBar from './ModeratorTopNavBar';

export default connect(mapStateToProps, mapDispatchToProps)(ModeratorTopNavBar);
