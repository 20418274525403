import {
  adminLogin,
} from '../../redux/auth/actions';

export const mapStateToProps = () => ({

});

export const mapDispatchToProps = dispatch => ({
  login: (email, password, history) => {
    dispatch(adminLogin(email, password, history));
  },
});
