import { verifyMeetingPassword } from '../../redux/meeting/actions';

export const mapStateToProps = () => ({

});

export const mapDispatchToProps = dispatch => ({
  verifyPasswordAndJoin: (password) => {
    dispatch(verifyMeetingPassword(password));
  },
});
