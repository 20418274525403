import React from 'react';
import {
  AppBar,
  CssBaseline,
  Toolbar,
} from '@material-ui/core';
import './topNavBar.css';
import propTypes from 'prop-types';
import { codeFrameCodeShareUrlForParticipants, codeFrameURL } from '../../configFiles/config';
import frontEndConfigFile from '../../configFiles/frontendConfigFile';
import myAnatomyLogo from '../../images/myAnatomyLogo.png';
import IconButton from '@material-ui/core/IconButton';
import { BiCodeBlock } from 'react-icons/bi';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';

class TopNavBar extends React.Component {
  constructor (props){
    super(props);
    this.state = {
      openedCodeShareWindowForParticipant: [],
      openedNotesWindowForParticipant: [],
      openedWhiteboardWindowForParticipant: [],
    };
  }

  componentDidMount () {
    window.addEventListener('beforeunload', this.closeEventForCodeShareBoard);
    window.addEventListener('beforeunload', this.closeEventForNotesBoard);
    window.addEventListener('beforeunload', this.closeEventForWhiteboardBoard);
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.closeEventForCodeShareBoard);
    window.removeEventListener('beforeunload', this.closeEventForNotesBoard);
    window.removeEventListener('beforeunload', this.closeEventForWhiteboardBoard);
  }

  closeEventForWhiteboardBoard = () => {
    const { openedWhiteboardWindowForParticipant } = this.state;

    openedWhiteboardWindowForParticipant.forEach((board) => {
      board.close();
    }
    );
    this.setState({
      openedWhiteboardWindowForParticipant: [],
    });
  }

  closeEventForNotesBoard = () => {
    const { openedNotesWindowForParticipant } = this.state;

    openedNotesWindowForParticipant.forEach((board) => {
      board.close();
    }
    );
    this.setState({
      openedNotesWindowForParticipant: [],
    });
  }

  closeEventForCodeShareBoard = () => {
    const {
      openedCodeShareWindowForParticipant,
    } = this.state;

    openedCodeShareWindowForParticipant.forEach((board) => {
      board.close();
    }
    );
    this.setState({
      openedCodeShareWindowForParticipant: [],
    });
  }

  showCodeShareBoard = () => {
    const {
      token,
      meetingId,
      externalMeetingId,
      name,
    } = this.props;
    let windowHeight = 440;
    let windowWidth = 630;

    let adjustFromTop = 0;

    const { openedCodeShareWindowForParticipant } = this.state;

    if (window.screen) {
      windowHeight = (window.screen.availHeight * 70) / 100;
      windowWidth = (window.screen.availWidth);
      adjustFromTop = (window.screen.availHeight * 15) / 100;
    }

    let codeShareURL = codeFrameCodeShareUrlForParticipants;

    codeShareURL = codeShareURL.replace('<<meetingId>>', meetingId);
    codeShareURL = codeShareURL.replace('<<meetingToken>>', token);
    codeShareURL = codeShareURL.replace('<<externalMeetingId>>', externalMeetingId);
    codeShareURL = codeShareURL.replace('<<participantName>>', name);
    const openWindow = window.open(
      codeShareURL,
      '_blank',
        `
          width=${windowWidth},
          height=${windowHeight},
          top=${adjustFromTop},
          location=no,
        `
    );

    openedCodeShareWindowForParticipant.push(openWindow);

    this.setState({
      openedCodeShareWindowForParticipant,
    });
  }

  showNotesView = () => {
    let windowHeight = 440;
    let windowWidth = 630;

    let adjustFromTop = 0;

    const { openedNotesWindowForParticipant } = this.state;

    if (window.screen) {
      windowHeight = (window.screen.availHeight * 70) / 100;
      windowWidth = (window.screen.availWidth);
      adjustFromTop = (window.screen.availHeight * 15) / 100;
    }

    let codeframeUrl = frontEndConfigFile.CODE_FRAME_URL.frontend;
    codeframeUrl = codeframeUrl.replace(/<<codeFrameConfigUrl>>/g, codeFrameURL);

    let notesViewUrl = frontEndConfigFile.NOTES.url;
    notesViewUrl = notesViewUrl.replace('<<codeFrameUrl>>', codeframeUrl);
    //let notesViewUrl = 'https://codeframe.myanatomy.in/';
    const openWindow = window.open(
      notesViewUrl,
      '_blank',
      `
          width=${windowWidth},
          height=${windowHeight},
          top=${adjustFromTop},
          location=no,
        `
    );

    openedNotesWindowForParticipant.push(openWindow);

    this.setState({
      openedNotesWindowForParticipant,
    });
  }

  showWhiteboardView = () => {
    let windowHeight = 440;
    let windowWidth = 630;

    let adjustFromTop = 0;

    const { openedWhiteboardWindowForParticipant } = this.state;

    if (window.screen) {
      windowHeight = (window.screen.availHeight * 70) / 100;
      windowWidth = (window.screen.availWidth);
      adjustFromTop = (window.screen.availHeight * 15) / 100;
    }
    const { externalMeetingId } = this.props;
    //  * -----Uncomment the below lines after integration when getting purpose and client id and pass the url--- *
    let codeframeUrl = frontEndConfigFile.CODE_FRAME_URL.frontend;
    codeframeUrl = codeframeUrl.replace(/<<codeFrameConfigUrl>>/g, codeFrameURL);

    let whiteBoardUrl = frontEndConfigFile.WHITEBOARD.url;
    whiteBoardUrl = whiteBoardUrl.replace('<<codeFrameUrl>>', codeframeUrl);
    whiteBoardUrl = whiteBoardUrl.replace('<<purposeId>>', externalMeetingId);
    whiteBoardUrl = whiteBoardUrl.replace('<<clientId>>', '');

    const openWindow = window.open(
      whiteBoardUrl,
      '_blank',
      `
            width=${windowWidth},
            height=${windowHeight},
            top=${adjustFromTop},
            location=no,
          `
    );

    openedWhiteboardWindowForParticipant.push(openWindow);

    this.setState({
      openedWhiteboardWindowForParticipant,
    });
  }

  render () {

    const {
      hasMeetingStarted,
      hasMeetingEnded,
      isPasswordAccessEnabled,
      isPasswordVerified,
    } = this.props;

    return (
      <div>
        <CssBaseline />
        <AppBar
          style={{
            background: '#323768',
            height: '50px',
          }}
          position="fixed"
        >
          <Toolbar>
            <div className='company'>
              <div className='company-logo'>
                <a href='https://myanatomy.in/#/home'><img style={{ height: '41px' }} src={myAnatomyLogo} alt='MA'/></a>
              </div>
            </div>
            {hasMeetingStarted && !hasMeetingEnded  && ((isPasswordAccessEnabled && isPasswordVerified) || (!isPasswordAccessEnabled)) ?
              <div className='codeShareOption'>
                <IconButton
                  style={{
                    color: 'white',
                  }}
                  title = 'Open code share board'
                  onClick={() => {this.showCodeShareBoard();}}
                >
                  <BiCodeBlock />
                </IconButton>
              </div>
              :
              null
            }
            {hasMeetingStarted && !hasMeetingEnded  && ((isPasswordAccessEnabled && isPasswordVerified) || (!isPasswordAccessEnabled)) ?
              <div className='notesOption'>
                <IconButton
                  style={{
                    color: 'white',
                  }}
                  title = 'Take Notes'
                  onClick={() => {this.showNotesView();}}
                >
                  <AssignmentIcon />
                </IconButton>
              </div>
              :
              null
            }
            {hasMeetingStarted && !hasMeetingEnded  && ((isPasswordAccessEnabled && isPasswordVerified) || (!isPasswordAccessEnabled)) ?
              <div className='whiteboardOption'>
                <IconButton
                  style={{
                    color: 'white',
                  }}
                  title = 'Open Whiteboard'
                  onClick={() => {this.showWhiteboardView();}}
                >
                  <CastForEducationIcon />
                </IconButton>
              </div>
              :
              null
            }
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

TopNavBar.propTypes = {
  meetingId: propTypes.string,
  token: propTypes.string,
  hasMeetingStarted: propTypes.bool,
  hasMeetingEnded: propTypes.bool,
  externalMeetingId: propTypes.string,
  name: propTypes.string,
  isPasswordAccessEnabled: propTypes.bool,
  isPasswordVerified: propTypes.bool,
};

export default TopNavBar;
