import React from 'react';
import queryString from 'query-string';
import propTypes from 'prop-types';
// import { toast, ToastContainer } from 'react-toastify';
import Loader from '../Loader/Loader';

class StartMeetingWithToken extends React.Component {
  constructor (props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount () {
    // Get meeting token from the URL.
    // Call the backend to get the meeting details.
    const {
      getMeetingDetails,
      history,
    } = this.props;

    let url = this.props.history.location.search;
    let queryParams = queryString.parse(url);

    const {
      token,
    } = queryParams;
    getMeetingDetails(token, history);
  }

  render () {
    const {
      isFetchingMeetingDetails,
    } = this.props;
    return (
      <div className='startMeetingViewContainer'>
        {
          isFetchingMeetingDetails ?
            <Loader />
            :
            null
        }
      </div>
    );
  }
}

StartMeetingWithToken.propTypes = {
  getMeetingDetails: propTypes.func,
  history: propTypes.object,
  isFetchingMeetingDetails: propTypes.bool,
};

StartMeetingWithToken.defaultProps = {
  getMeetingDetails: () => { },
  isFetchingMeetingDetails: false,
};


export default StartMeetingWithToken;
