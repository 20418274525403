import React from 'react';

import '../../App.css';

import {
  myAnatomyHomePageUrl,
  openInNewWindowOptions,
} from '../../configFiles/config';

const openMyAnatomyWebsite = () => {
  window.open(myAnatomyHomePageUrl, openInNewWindowOptions);
};

const LeftMeetingHomePageView = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className='homePageContainer'>
          <div className='homePageContentContainer'>
            <div className='homePageAboutText'>
              MyAnatomy&apos;s MOVIE platform. <br />
            </div>
            <div className='homePageVisitOurText'>
              visit our&nbsp;
              <div
                className='homePageWebsiteHyperLink'
                onClick={openMyAnatomyWebsite}
                title={`Visit : ${myAnatomyHomePageUrl}`}
              >
                website
              </div>
                &nbsp;to know more
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default LeftMeetingHomePageView;
