import React from 'react';

import propTypes from 'prop-types';

import { Button, TextField } from '@material-ui/core';

import TopNavBar from '../TopNavBar';

import './meetingPasswordView.css';

import { minimumMeetingPasswordLength } from '../../configFiles/config';


class MeetingPasswordView extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      enteredPassword: '',
      enteredPasswordErrorMessage: '',
    };
  }

  handlePasswordUpdate = (event) => {
    event.persist();
    this.setState(() => ({
      enteredPassword: event.target.value,
      enteredPasswordErrorMessage: '',
    }));
  };

  verifyMeetingPasswordAndJoinMeeting = () => {
    const {
      enteredPassword,
    } = this.state;

    let shouldVerifyPassword = true;

    if (!enteredPassword || (enteredPassword &&  (enteredPassword < minimumMeetingPasswordLength))) {
      this.setState(() => ({
        enteredPasswordErrorMessage: 'Password length does not match the minimum password length',
      }));
      shouldVerifyPassword = false;
    }

    if (shouldVerifyPassword) {
      const {
        verifyPasswordAndJoin,
      } = this.props;
      // Call the verify password action.
      verifyPasswordAndJoin(enteredPassword);
    }
  }

  render () {
    const {
      enteredPassword,
      enteredPasswordErrorMessage,
    } = this.state;

    const disableJoinMeetingButton = (!enteredPassword || (enteredPassword && enteredPassword.length < minimumMeetingPasswordLength));
    return (
      <div className='meetingPasswordView'>
        <TopNavBar />
        <div className='meetingPasswordViewContainer'>
          <div className='meetingPasswordViewHeaderText'>
            This meeting requires a password to join
          </div>
          <div className='meetingPasswordViewPasswordContainer'>
            <div className='meetingPasswodInputContainer'>
              <span className='enterPasswordText'>Enter password</span>
              <TextField
                id='meetingPasswordInputField'
                // label="Name"
                placeholder='*****'
                variant='outlined'
                required={true}
                errortext={enteredPasswordErrorMessage}
                onChange={this.handlePasswordUpdate}
                style={{
                  justifyContent: 'center',
                  display: 'flex',
                  width: '100%',
                  paddingTop: '5px',
                }}
              />
            </div>
            <div
              className='meetingPasswordVerfiyButton'
              style={
                disableJoinMeetingButton ?
                  {
                    cursor: 'not-allowed',
                  }
                  :
                  {
                  
                  }
              }
              // title={
              //   disableJoinMeetingButton ?
              //     'Enter password to verify the password'
              //     :
              //     'Click to verify the meeting password & join the meeting'
              // }
            >
              <Button
                id='verifyMeetingPasswordButton'
                variant='contained'
                onClick={this.verifyMeetingPasswordAndJoinMeeting}
                title={
                  disableJoinMeetingButton ?
                    'Enter password to verify'
                    :
                    'Click to verify the password'
                }
                className={
                  disableJoinMeetingButton ?
                    'disabledVerifyMeetingPasswordButton'
                    :
                    'verifyMeetingPasswordButton'
                }
                disabled={disableJoinMeetingButton}
              >
                Verify
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MeetingPasswordView.propTypes = {
  verifyPasswordAndJoin: propTypes.func,
};

MeetingPasswordView.defaultProps = {
  verifyPasswordAndJoin: () => { },
};

export default MeetingPasswordView;
