import { getMeetingDetails } from '../../redux/meeting/actions';

export const mapStateToProps = state => ({
  adminToken: state.auth.adminToken,
  meetingDetailsOfSelectedMeeting: state.meeting.meetingDetailsOfSelectedMeeting,
});

export const mapDispatchToProps = dispatch => ({
  getMeetingDetailsForSelectedMeeting: (meetingId) => {
    dispatch(getMeetingDetails(meetingId));
  },
});
