import { getMeetingDetailsUsingMeetingToken } from '../../redux/meeting/actions';

export const mapStateToProps = state => ({
  isFetchingMeetingDetails: state.meeting.isFetchingMeetingDetails,
});

export const mapDispatchToProps = dispatch => ({
  getMeetingDetails: (token, history) => {
    dispatch(getMeetingDetailsUsingMeetingToken(token, history));
  },
});
