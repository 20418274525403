import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import promiseMiddleware from 'redux-promise-middleware';
// import persistState, { mergePersistedState } from 'redux-localstorage';
// import filter from 'redux-localstorage-filter';
// import adapter from 'redux-localstorage-adapter';

// import { save, load } from "redux-localstorage-simple"

import combinedReducers from '../redux';

const middlewares = process.env.NODE_ENV === 'development' ? [thunk, logger] : [thunk];

middlewares.push(promiseMiddleware);

// console.log('middlewares', middlewares);

const configureReduxStore = createStore(
  combinedReducers,
  applyMiddleware(...middlewares),
);

// const configureReduxStore = () => {
//   const reducer = compose(
//     mergePersistedState((initialState, persistedState) => {
//       // const newState = { ...initialState };

//       // return newState;
//       return initialState;
//     }),
//   )(combinedReducers);

//   const storage = compose(
//     filter(
//       [
      
//       ]
//     )
//   )(adapter(window.localStorage));

//   const composeEnhancers =
//     process.env.NODE_ENV === 'development'
//       ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
//       : compose;

//   const middlewareBasedOnEnv = process.env.NODE_ENV === 'development' ?
//       [thunk, logger]
//     :
//       [thunk];

//   const enhancers = composeEnhancers(
//     applyMiddleware(...middlewareBasedOnEnv, promiseMiddleware()),
//     persistState(storage, 'profile'),
//   );

//   const store = createStore(reducer, enhancers);

//   return store;
// };

// console.log('configureReduxStore', configureReduxStore);
export default configureReduxStore;
