import React from 'react';
import styled from 'styled-components';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${fade('#FFFFFF', 0.57)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = () => {
  return (
    <div>
      <LoaderWrapper>
        <CircularProgress variant="indeterminate" />
      </LoaderWrapper>
    </div>
  );
};

export default Loader;
