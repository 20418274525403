import React from 'react';


const PageNotFoundView = () => (
  <div style = {{ fontSize: '20px', color: 'black', justifyContent: 'center', display: 'flex'  }}>
    The requested resource is not available.
  </div>
);

export default PageNotFoundView;
